import React, { createContext, useCallback, useMemo, useState } from 'react';

type ProviderValue = {
  currentRoute: any;
  generateCurrentRoute: any;
};

export const CurrentRoutePath = createContext<ProviderValue>({
  currentRoute: null,
  generateCurrentRoute: (d: any) => d,
});

const CurrentRouteProvider = ({ children }) => {
  const [currentRoute, setCurrentRoute] = useState<ProviderValue>();
  const obj = useMemo(
    () => ({
      currentRoute,
      generateCurrentRoute: setCurrentRoute,
    }),
    [currentRoute, setCurrentRoute]
  );

  return (
    <CurrentRoutePath.Provider value={obj}>
      {children}
    </CurrentRoutePath.Provider>
  );
};

export default CurrentRouteProvider;
