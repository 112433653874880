import { Action, Dispatch } from 'redux';
import { SET_WELCOME_POPUP } from '../constants';

export const showWelcomePopup = () => {
  return (dispatch: Dispatch): Action =>
    dispatch({
      type: SET_WELCOME_POPUP,
      payload: true,
    });
};

export const hideWelcomePopup = () => {
  return (dispatch: Dispatch): Action =>
    dispatch({
      type: SET_WELCOME_POPUP,
      payload: false,
    });
};
