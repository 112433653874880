import { uuidv4 } from 'app/util/helper';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { View } from 'react-native';
import Dialog from '../../../components/dialog';
import { Button } from '../../../components/button/common-button';
import DialogTitle from '../../../components/dialog/dialog-titel';
import { useSx } from 'dripsy';
import DialogAction from '../../../components/dialog/dialog-action';
import { useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { useCurrentRoute } from '../use-current-route';
import { I18Text } from 'app/provider/i18-provider';
import { getClientConfig, getLoaded, getUser } from '../../memoizedSelectors';

export interface AlertBody {
  type: 'warn' | 'error' | 'info';
  message: string;
  title: string;
  buttons: { name: string; buttonStyle?: any; onPress?: (v: string) => void }[];
  id?: string;
}

const AlertContext = createContext<(body: AlertBody) => void>(() => {
  return;
});

export const useAlert = () => {
  return useContext(AlertContext);
};

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const sx = useSx();
  const [alerts, dispatch]:any[] = useReducer<any>(
    (
      state: AlertBody[],
      action: { type: 'add' | 'remove'; data?: AlertBody; id?: string }
    ) => {
      if (action.type === 'add')
        return [...state, { ...action.data, id: uuidv4() }];
      else return state.filter((f) => f.id != action.id);
    },
    []
  );

  // on-boarding mandatory for student and faculty
  const { user } = useSelector(
    getUser
  );
  const {loaded} = useSelector(getLoaded)
  const {clientConfig} = useSelector(getClientConfig)
  const { push } = useRouter();
  const currentRoute = useCurrentRoute();
  useEffect(() => {
    if (!loaded) return;
    if (
      user?._id &&
      // user?.role <= 31 &&
      // !user?.clientRole &&
      !user.onboardingSkip &&
      !user?.preferenceUpdated && 
      clientConfig.onboarding
    ) {
      if (
        currentRoute &&
        ![
          '/account/signup',
          '/account/on-boarding',
          '/account/signin',
          '/studymaterial',
          '/verify-email',
          '/job-courses',
          '/checkout'
        ].some(url => currentRoute.startsWith(url))
      ) {
        push(
          `/account/on-boarding${currentRoute ? `?continue=${currentRoute}` : ''
          }`
        );
      }
    }
  }, [user, loaded, currentRoute]);

  return (
    <AlertContext.Provider
      value={(b: AlertBody) => dispatch({ type: 'add', data: b })}
    >
      <View style={{ flex: 1 }}>
        {children}
        {alerts.map((a, i) => (
          <Dialog
            key={a.id || 'confirm-' + i}
            modalVisible={true}
            onCloseModal={() => {
              // dispatch({ type: 'remove', id: a.id });
            }}
            styles={sx({
              minHeight: 120,
              width: [350, 400, 500],
              justifyContent: 'space-between',
            })}
          >
            <DialogTitle styles={sx({ paddingX: 20, paddingY: 20 })} divider>
              {a.title}
            </DialogTitle>
            <View
              style={sx({
                minHeight: 120,
                padding: 10,
                justifyContent: 'space-between',
              })}
            >
              <I18Text style={{ textAlign: 'left', lineHeight: 21 }}>{a.message}</I18Text>
              <DialogAction>
                {a?.buttons?.map((b, bi) => (
                  <Button
                    // title={b.name}
                    key={`${a.id}-but-${bi}`}
                    onPress={() => {
                      b?.onPress && b?.onPress(b.name);
                      dispatch({ type: 'remove', id: a.id });
                    }}
                    textStyle={{
                      color: b.buttonStyle?.color || 'white',
                      fontSize: 15
                    }}
                    style={[
                      {
                        width: 80,
                      },
                      b.buttonStyle,
                    ]}
                  >
                    <I18Text style={[sx({ color:'#fff' })]} >{b.name}</I18Text>
                  </Button>
                ))}
              </DialogAction>
            </View>
          </Dialog>
        ))}
      </View>
    </AlertContext.Provider>
  );
};
