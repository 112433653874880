import { uuidv4 } from 'app/util/helper';
import { ToastMessage } from '../reducers/toasts';


export const addMessage = (message: ToastMessage) => ({
  type: 'ADD_MESSAGE',
  payload: {
    id: uuidv4(),
    message,
  },
});

export const removeToast = (message: any) => ({
  type: 'REMOVE_MESSAGE',
  payload: message,
});
