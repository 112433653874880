const loaded = (state = false, action: { type: any }) => {
  switch (action.type) {
    case 'GLOBAL_LOADED':
      return true;

    default:
      return state;
  }
};
export default loaded;
