export interface ToastMessage {
  id?: string
  message: string
  error?: any
  type?: 'info' | 'warn' | 'error'| 'success'
  timeout?: number
}

const initialState: ToastMessage[] = [];
const toasts = (state = initialState, action: { type: any; payload: { message: { timeout: any }; id: string | undefined }; asyncDispatch: (arg0: { type: string; payload: { id: any } }) => void }) => {
  switch (action.type) {
    case 'ADD_MESSAGE': {
        setTimeout(() => {
          action.asyncDispatch({
            type: 'REMOVE_MESSAGE',
            payload: {
              id: action.payload.id,
            },
          });
        }, action.payload.message.timeout || 3000);
      
      return [...state, { ...action.payload.message, id: action.payload.id }];
    }

    case 'REMOVE_MESSAGE': {
      return state.filter((m) => m && m.id != action.payload.id);
    }
    
    default:
      return state;
  }
};
export default toasts;
