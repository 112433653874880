import React, { useEffect, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import * as raf from 'raf';
import { Loader } from 'app/components/loader';
import { useCalculateFontSize } from 'app/redux/hooks/use-calculate-font-size';
import { makeUseAxiosHook } from 'app/redux/store';
import DeviceInfo from 'react-native-device-info';
import { UPDATE_CLIENT_CONFIG } from 'app/redux/constants';
import { config } from 'app/redux/config';
import { Audio } from 'expo-av';
import { Camera } from 'expo-camera';
import { DripsyProvider, makeTheme } from 'dripsy';
import { getClientConfig, getLoaded, getUser } from '../redux/memoizedSelectors';
import Constants from 'expo-constants';

// Function to request camera permissions
const requestCameraPermission = async () => {
  const { status } = await Camera.requestCameraPermissionsAsync();
  // Stop camera use
  return status === 'granted';
};

// Function to request audio permissions
const requestAudioPermission = async () => {
  try {
    const { status } = await Audio.requestPermissionsAsync();
    return status === 'granted';
  } catch (error:any) {
    console.error('Error requesting audio permission:', error);
    return false;
  }
};


raf.polyfill();

const webFont = (font: string) =>
  Platform.select({
    web: `${font}, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Inter-serif`,
    default: font,
  });

export function Dripsy({
  children,
  getDetails,
}: {
  children: React.ReactNode;
  getDetails: any;
}) {
  const getFontSize = useCalculateFontSize();
  const {loaded: _loaded} = useSelector(getLoaded);
  const {clientConfig} = useSelector(getClientConfig);
  const {user} = useSelector(getUser);

  const [ssr, setSSR] = useState(false);

  useEffect(() => {
    setSSR(true);
  }, []);
  
  const theme = useMemo((): any =>{
    const theme = makeTheme({
      fonts: {
        root: 'Arial',
      },
      text: {
        btnText: getFontSize(15, 12),
        sectionTitle: getFontSize(24, 15),
        sectionSubTitle: getFontSize(17, 13),
        homeBtnText: getFontSize(17, 12),
      },
      fontSizes: {
        root: 'Arial', // base font size in pixels
        // other font sizes
      },
      space: {
        0: 0,
        1: 4,
        2: 8,
        3: 16,
        4: 32,
        5: 64,
        6: 128,
        7: 256,
        $0: 0,
        $1: 4,
        $2: 8,
        $3: 16,
        $4: 32,
        $5: 64,
        $6: 128,
        $7: 256,
      },
      types: {
        onlyAllowThemeValues: {
          space: 'always'
        }
      },
      customFonts: {
        InterRegular: {
          bold: webFont('Arial'),
          default: webFont('Arial'),
        },
        Inter: {
          bold: webFont('Arial'),
          default: webFont('Arial'),
        },
      },
      // https://www.dripsy.xyz/usage/theming/create
      colors: {
        $primary: clientConfig?.primaryColor || '#4C3E79',
        $secondary: clientConfig?.secondaryColor || '#00A79D',
        $textColor: '#000000',
        $textColorTwo: '#2f3443',
        $white: '#fff',
        $black: '#000',
        $pColor: '#888',
        $red: '#eb0519',
        $gray: '#D9D9D9',
        $yellow: '#FFCB05',
        $disabled: '#99999033',
        // new theme color code added for new pages
        $tertiary: '#F47979',
        $fontGrey: '#000000',
        $darkSilver: '#646977',
        //exam-genie color code for black theme
        $newBlack: '#343434',
        $darkBlue: '#21005B',
        $textBlue: '#07294D',
        $skyBlue: '#E7EBFB',
        $lightGrey: '#dddd',
        $cultured: '#f3f1fa',
        $brightGrey: '#EAEAEA',
        // Exam-adda
        $aquaSpring: '#EDF9F5',
        $jaggedIce: '#C4E7DB',
        $paleTeal: '#66C7A5',
        $merino: '#F7EEE5',
        $almond: '#EFDDCD',
        $whiskey: '#DCA06A',
        $titanWhite: '#F1F0FE',
        $geyser: '#DBD9EF',
        $blueBell: '#A193CE',
        $desertStorm: '#F8F8F8',
      },
      shadows: {
        md: {
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          elevation: 5,
        },
      },
      buttons: {
        label: {
          fontWeight: '600',
          fontSize: 3,
          textAlign: 'center',
          fontFamily: 'Inter',
        },
        primary: {
          bg: '$primary',
          fontFamily: 'Inter',
          borderColor: '$primary',
          label: {
            color: '#fff',
          },
        },
        secondary: {
          bg: '$secondary',
          fontFamily: 'Inter',
          borderColor: '$secondary',
          label: {
            color: '#fff',
          },
        },
      }
    });
    return theme;
  }, [clientConfig])
  // get client details
  const dispatch = useDispatch();
  const [{ loading }, refetch] = makeUseAxiosHook('sso', {
    manual: true,
    autoCancel:false
  })({
    url: '/api/v1/getClientDetails',
    method: 'GET',
  });

  useEffect(() => {
    let query = '';
    let paramsKey = '';
    if (Platform.OS === 'web') {
      let domain = '';
      if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
        domain =
          typeof window !== 'undefined'
            ? window.location.hostname.replace('www.', '')
            : '';
      } else domain = config?.domain;
      query = `${domain}`;
      paramsKey = 'domain';
    } else if (Platform.OS === 'android') {
      query = Constants?.expoConfig?.extra?.clientAppId || `${DeviceInfo.getBundleId()}`;
      paramsKey = 'androidProductPackageId';
    } else if (Platform.OS === 'ios') {
      query = Constants?.expoConfig?.extra?.clientAppId || `${DeviceInfo.getBundleId()}`;
      paramsKey = 'iosProductPackageId';
    }
    refetch({
      params: {
        [paramsKey]: query,
      },
    }).then((d) => {
      const { data } = d;
      if (data?.length) {
        const {
          clientDetails: {
            clientConfig: _clientConfig,
            _id,
            ...clientDetails
          } = { clientConfig: {}, _id: '' },
          ...client
        } = data[0];
        const contentGeneratePermission: [string] =
          client.contentGeneratePermission?.map((item: { id: any; }) => `${item?.id}`) || [];

        const newPayload = {
          ...client,
          ..._clientConfig,
          ...clientDetails,
          clientDetailsId: _id,
          contentGenerate:
            user?.role === 65535 ||
            contentGeneratePermission?.includes(`${user?.clientRole}`) ||
            contentGeneratePermission?.includes(`${user?.role}`),
          academicSetting: {
            university:
              clientDetails?.universityPlaceholderName || 'University',
            courses: clientDetails?.coursePlaceholderName || 'Courses',
            semester:
              clientDetails?.semesterPlaceholderName || 'Academics session',
            specialization:
              clientDetails?.specializationPlaceholderName || 'Specialization',
          },
        };
        dispatch({
          type: UPDATE_CLIENT_CONFIG,
          payload: newPayload,
        });
        getDetails(newPayload);
      }
    });
  }, []);

  useEffect(() => {
    const contentGeneratePermission: [string] =
      clientConfig.contentGeneratePermission?.map((item: { id: any; }) => `${item?.id}`) ||
      [];
    const newPayload = {
      ...clientConfig,
      contentGenerate:
        user?.role === 65535 ||
        contentGeneratePermission?.includes(`${user?.clientRole}`) ||
        contentGeneratePermission?.includes(`${user?.role}`),
    };
    dispatch({
      type: UPDATE_CLIENT_CONFIG,
      payload: newPayload,
    });
    getDetails && getDetails(newPayload);
  }, [user]);

    // Function to handle camera initialization
    const initializeCamera = async () => {
      try {
        // const cameraPermission = await requestCameraPermission();
        // const audioPermission = await requestAudioPermission();
  
        // if (!cameraPermission || !audioPermission) {
        //   // Handle case where permission is denied for camera or audio
        //   // console.log('Camera or audio permission denied');
        //   dispatch({
        //     type: UPDATE_GLOBAL_STORE_DATA,
        //     payload: {
        //       hasCameraPermission:false,
        //       hasAudioPermission:false
        //     },
        //   });
        //   return;
        // }
  
        // Access the camera and start using it
        // Your camera initialization code goes here
        // dispatch({
        //   type: UPDATE_GLOBAL_STORE_DATA,
        //   payload: {
        //     hasCameraPermission:true,
        //     hasAudioPermission:true
        //   },
        // });
      } catch (error:any) {
        // Handle errors
        console.error('Error initializing camera:', error);
      }
    };
  
  
    useEffect(() => {
      initializeCamera();
    }, []);

  return  _loaded && !loading && ssr ? (
    <DripsyProvider
      theme={theme}
      // this disables SSR, since react-native-web doesn't have support for it (yet)
      ssr={false}
    >
      {children}
    </DripsyProvider>
  ) : (
    <>
      <Loader />
      <View style={{ display: 'none' }}>
        <DripsyProvider
          theme={theme}
          // this disables SSR, since react-native-web doesn't have support for it (yet)
          ssr={false}
        >
          {children}
        </DripsyProvider>
      </View>
    </>
  );
}
