import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useToast } from 'app/redux/hooks/use-toast';
import { initializeApp, FirebaseApp } from 'firebase/app';
import {
  getMessaging,
  onMessage,
  getToken as fcmGetToken,
  Messaging,
  Unsubscribe,
  NotificationPayload
} from 'firebase/messaging';
import { config } from '../../redux/config';
import { View, Text, H5, useSx } from 'dripsy';
import Modal from 'react-native-modal';
import { Button } from 'app/components/button/common-button';
import { Gradient } from 'dripsy/gradient';
import { connect, useSelector } from 'react-redux';
import { makeUseAxiosHook } from 'app/redux/store';
import * as welcomePopupAction from 'app/redux/actions/welcomePopup.action';
import * as NotificationAction from 'app/redux/actions/notification.action';
import { checkUndefined, getAsyncStorageData } from 'app/util/helper';
import { getClientConfig, getUser, getWelcomePopup } from '../../redux/memoizedSelectors';


type ProviderValue = {
  notification: NotificationPayload | undefined;
  generatePushNotificationsTokenForWeb: () => Promise<string | undefined>;
};

const FirebaseMessagingContext = React.createContext<ProviderValue>({
  notification: undefined,
  generatePushNotificationsTokenForWeb: async () => undefined,
});

interface FirebaseMessagingProviderProps {
  user: any;
  notification: any,
  welcomePopup: any;
  children: React.ReactNode;
  hideWelcomePopup: () => void;
  showWelcomePopup: () => void;
  getUserNotifications: (userId: any) => void;
}
const FirebaseMessagingProviderImpl: React.FC<FirebaseMessagingProviderProps> = ({
  user,
  welcomePopup,
  children,
  hideWelcomePopup,
  showWelcomePopup,
  getUserNotifications
}) => {
  const { clientConfig } = useSelector(getClientConfig);
  const [app, setApp] = useState<FirebaseApp>();
  const [messaging, setMessaging] = useState<Messaging>();
  const [notification, setNotification] = useState<NotificationPayload | undefined>();
  const sendMessage = useToast();
  const [showModal, setShowModal] = useState<boolean>(false);
  const sx = useSx();

  const [{ }, updateFirstLogin] = makeUseAxiosHook('sso', { manual: true })(
    {
      url: 'user-update',
      method: 'PATCH',
    },
    {
      userId: user?._id,
    }
  );

  const firebaseConfig = {
    apiKey: config.firebaseApiKey,
    authDomain: config.firebaseAuthDomain,
    databaseURL: config.firebaseDatabaseURL,
    projectId: config.firebaseProjectId,
    storageBucket: config.firebaseStorageBucket,
    messagingSenderId: config.firebaseMessagingSenderId,
    appId: config.firebaseAppId,
    measurementId: config.firebaseMeasurementId,
  };

  const VAPID_KEY = config.firebaseVapidKey;
  const getToken = useCallback(async () => {
    if (messaging) {
      try {
        await Notification.requestPermission();
        const currentToken = await fcmGetToken(messaging, { vapidKey: VAPID_KEY });
        if (currentToken) {
          return currentToken;
        } else {
          // console.log('No registration token available. Request permission to generate one.');
          return undefined;
        }
      } catch (err) {
        // console.log('An error occurred while retrieving token:', err);
        return undefined;
      }
    }
  }, [messaging]);

  const closeModal = () => {
    if (showModal) {
      hideWelcomePopup();
      updateFirstLogin({ data: { firstLogin: true } });
      setShowModal(false);
    }
  };

  const initWelcomePopup = async () => {
    const userRegistered = await getAsyncStorageData('userRegistered');
    if (userRegistered === 'true' && user?.clientId === '1') {
      showWelcomePopup();
    } else {
      hideWelcomePopup();
    }
  };

  useEffect(() => {
    // Initialize Firebase
    if(!checkUndefined(clientConfig?.exploreClassroom, true)) return;
    setApp(initializeApp(firebaseConfig));
    initWelcomePopup();
  }, []);

  useEffect(() => {
    if (user?.firstLogin === false && welcomePopup && user?.clientId === '1') {
      setShowModal(true);
    }
  }, [user, welcomePopup]);

  useEffect(() => {
    if (app) {
      try {
        setMessaging(getMessaging(app));
      } catch(e) {
        // ignore
      }
    }
  }, [app]);

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;
    if (messaging) {
      try {
        unsubscribe = onMessage(messaging, (payload) => {
          setNotification(payload?.notification);
          sendMessage({
            message: `${payload?.notification?.title}`,
            type: 'info',
          });
         if(user) getUserNotifications(user?._id);
        });
        return () => {
          if (unsubscribe) {
            unsubscribe();
          }
        };
      } catch (error:any) {
        // console.log(error);
      }
    }
  }, [messaging]);

  const generatePushNotificationsTokenForWeb = React.useCallback(async () => {
    return await getToken();
  }, [getToken]);

  return (
    <FirebaseMessagingContext.Provider
      value={{ notification, generatePushNotificationsTokenForWeb }}
    >
      {showModal && (
        <View
          sx={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.5)',
            zIndex: 999,
          }}
        />
      )}
      <Modal
        nativeID="user-registered"
        isVisible={showModal}
        onBackButtonPress={() => closeModal()}
        onSwipeCancel={() => closeModal()}
        onBackdropPress={() => closeModal()}
        hasBackdrop={true}
        animationOut={'fadeOut'}
        animationIn={'fadeIn'}
        backdropColor={'transparent'}
        style={sx({
          display: 'flex',
          alignItems: 'center',
        })}
      >
        <View
          sx={{
            width: '100%',
            maxWidth: 625,
            backgroundColor: '#fff',
            zIndex: 999,
            px: 40,
            py: 40,
            alignItems: 'center',
            borderRadius: 15,
          }}
        >
          <H5
            style={sx({
              fontFamily: 'Inter',
              fontSize: [30, 40, 58],
              lineHeight: [34, 45, 72],
              textAlign: 'center',
              mt: 0,
              mb: 4,
              color: '$secondary',
              textShadowColor: '$primary',
              textShadowOffset: { width: 1, height: 2 },
              textShadowRadius: 0,
              letterSpacing: 2,
              borderBottomWidth: 2,
              borderStyle: 'dashed',
              borderColor: '$yellow',
            })}
          >
            Congratulations
          </H5>

          <Text
            style={sx({
              color: '$primary',
              fontSize: 36,
              lineHeight: 42,
              fontStyle: 'italic',
              textAlign: 'center',
              mb: 4,
            })}
          >
            You Have Unlocked a Free Trial
          </Text>
          <Gradient
            start={{ x: 0, y: 0.5 }}
            end={{ x: 0.5, y: 0 }}
            colors={['$secondary', '$primary']}
            style={[{ borderRadius: 5 }]}
          >
            <Button
              variantColor="primary"
              onPress={() => closeModal()}
              style={[
                sx({
                  color: 'white',
                  paddingY: 2,
                  paddingX: 30,
                  fontWeight: '700',
                  backgroundColor: 'none',
                }),
              ]}
            >
              Continue Exploring
            </Button>
          </Gradient>
        </View>
      </Modal>
      {children}
    </FirebaseMessagingContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state).user,
  welcomePopup: getWelcomePopup(state).welcomePopup,
});

const ConnectedFirebaseMessagingProvider = connect(mapStateToProps, {
  ...welcomePopupAction,
  ...NotificationAction,
})(FirebaseMessagingProviderImpl);

export { ConnectedFirebaseMessagingProvider as FirebaseMessagingProvider, FirebaseMessagingContext };
export const useFcmWebNotification = () => {
  const { generatePushNotificationsTokenForWeb, notification } = useContext(
    FirebaseMessagingContext
  );
  return { generatePushNotificationsTokenForWeb, notification };
};