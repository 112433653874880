export const structureModalReducer = (state = { isOpenModal : false }, action) => {
    switch (action.type)
    {
    
      case 'SET_MODAL_VALUE':
        return {
          ...state,
          isOpenModal: action.payload,
        };
      default:
        return state;
    }
  };
