import {
  GET_USER_CARTS_DETAILS_UPDATE,
  GET_USER_CARTS_DETAILS_SUCCESS,
} from 'app/redux/constants';

const initialState = { cartItems: [] };

const userWallet = (
  state = initialState,
  action: {
    type: any;
    payload: { data: { data: any } };
    error: { response: any; data: any };
  }
) => {
  switch (action.type) {
    case GET_USER_CARTS_DETAILS_SUCCESS: {
      const { data: [item = { cartItems: [], totalAmount: 0 }] = [] } =
        action.payload.data;
      return {
        ...item,
        cartItems: item.cartItems.filter((each) => !each.delete),
      };
    }
    case GET_USER_CARTS_DETAILS_UPDATE: {
      return { ...state, ...action.payload };
    }
    // case LOGOUT_SUCCESS: {
    //   return initialState;
    // }
    // case 'LOGOUT_FAIL': {
    //   return initialState;
    // }
    default: {
      return state;
    }
  }
};

export default userWallet;
