  import {
    GET_USER_PREFERENCE_SUCCESS,
    UPDATE_USER_PREFERENCE
  } from 'app/redux/constants';
  
  const initialState = null;
  
  const userPreference = (
    state = initialState,
    action: {
      type: any;
      payload: { data: { data: any } };
      error: { response: any; data: any };
    }
  ) => {
    switch (action.type) {
      case GET_USER_PREFERENCE_SUCCESS: {
       return action.payload.data?.data.length?action.payload.data?.data[0]:null;
      }
      // case LOGOUT_SUCCESS: {
      //   return initialState;
      // }
      // case 'LOGOUT_FAIL': {
      //   return initialState;
      // }
      case UPDATE_USER_PREFERENCE:{
        return action.payload;
      }
      default: {
        return state;
      }
    }
  };
  
  export default userPreference;
  