import {
  GET_USER_NOTIFICATIONS_SUCCESS,
  GET_USER_NOTIFICATIONS_UPDATE,
} from 'app/redux/constants';

const initialState = {

};

const userNotifications = (
  state = initialState,
  action: {
    type: any;
    payload: { data: { data: any } };
    error: { response: any; data: any };
  }
) => {

  switch (action.type) {
    case GET_USER_NOTIFICATIONS_SUCCESS: {
      return state; //action.payload.data; // Tmp fix
    }
    case GET_USER_NOTIFICATIONS_UPDATE: {
      return state; // Tmp fix{ ...state, ...action.payload?.data };
    }

    default: {
      return state;
    }
  }
};

export default userNotifications;
