import {
  GET_USER_PERMISSION_SUCCESS,
} from 'app/redux/constants';

const initialState = [];

const userPermission = (
  state = initialState,
  action: {
    type: any;
    payload: { data: { permissionList: any } };
    error: { response: any; data: any };
  }
) => {
  switch (action.type) {
    case GET_USER_PERMISSION_SUCCESS: {
      return action.payload.data?.permissionList || [];
    }
    // case LOGOUT_SUCCESS: {
    //   return initialState;
    // }
    // case 'LOGOUT_FAIL': {
    //   return initialState;
    // }
    default: {
      return state;
    }
  }
};

export default userPermission;
