import {
    UPDATE_GLOBAL_STORE_DATA,
  } from 'app/redux/constants';


  const initialState = {
    minimizeDashboardMenu:false,
    hasCameraPermission:false,
    hasAudioPermission:false,
    locale:'en'
  };
  
  const globalStore = (
    state = initialState,
    action: {
      type: any;
      payload: {
        data: { user: any };
      };
      asyncDispatch: (arg0: {
        type: string;
        payload?: { client: string; request: { url: string; method: string } };
      }) => void;
      error: { response: any; data: any };
    }
  ) => {
    switch (action.type) {
      case UPDATE_GLOBAL_STORE_DATA: {
        return { ...(state ? state : {}), ...action.payload };
      }
     
      default: {
        return state;
      }
    }
  };
  
  export default globalStore;
  