import React, { ReactEventHandler } from 'react';
import { Dripsy } from './dripsy';
import { NavigationProvider } from './navigation';
import { Provider as RDProvider } from 'react-redux';
import { store } from 'app/redux/store';
import Toasts from '../components/toasts/toast';
import { AlertProvider } from 'app/redux/hooks/alert';
import { FirebaseMessagingProvider as FcmWebProvider } from './firebase-messaging';
import CurrentRouteProvider from './current-route-name';
import { Offline } from 'app/components/offline';
import { Platform } from 'react-native';
import { I18Provider } from './i18-provider';
import { PerformanceProvider } from './performance';
import { PortalHost } from './portal-provider';
import { ChatProvider } from './chat-provider';

const FireBaseProviderDummy = ({ children }: any) => {
  return <>{children}</>;
};
export function Provider({
  children,
  setData,
}: Readonly<{
  children: React.ReactNode;
  setData: ReactEventHandler;
}>) {
  const FirebaseProvider = React.useMemo(() => {
    if (Platform.OS === 'web') {
      return FcmWebProvider;
    } else return FireBaseProviderDummy;
    // return FcmWebProvider;
  }, []);

  return (
    <PerformanceProvider>
      <RDProvider store={store}>
        <Dripsy getDetails={setData}>
          <I18Provider>
            <CurrentRouteProvider>
              <NavigationProvider>
                <PortalHost>
                  <AlertProvider>
                    <FirebaseProvider notification={undefined}>
                      <ChatProvider>{children}</ChatProvider>
                    </FirebaseProvider>
                  </AlertProvider>
                </PortalHost>
                <Toasts />
                {Platform.OS !== 'web' && <Offline />}
              </NavigationProvider>
            </CurrentRouteProvider>
          </I18Provider>
        </Dripsy>
      </RDProvider>
    </PerformanceProvider>
  );
}
