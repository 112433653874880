import { useSx, Row } from 'dripsy';
import React from 'react';
import { ViewStyle } from 'react-native';

interface DialogActionProps {
  children?: React.ReactNode
  style?: ViewStyle
}

export default function DialogAction({ children, style }: DialogActionProps) {
  const sx = useSx();
  return (
    <Row
      style={[
        sx({
          width: '100%',
          justifyContent: 'flex-end',
          alignSelf:'flex-end'
        }),
        style,
      ]}
    >
      {children}
    </Row>
  );
}
