import { config } from '../config';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const userLogout = async () => {
  if (Platform.OS === 'web') {
    document.cookie = `${config.authTokenName}=; path=/; domain=${config.cookie.domain}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `ln-language=; path=/; domain=${config.cookie.domain}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `yoloCanceled=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=${config.cookie.domain};`;
  } 
  if (Platform.OS !== 'web') {
    await AsyncStorage.removeItem(config.authTokenName);
  }
  await AsyncStorage.removeItem('permission');
  await AsyncStorage.removeItem('client');
  await AsyncStorage.removeItem('preference');
  await AsyncStorage.removeItem('user');
};

export default userLogout;
