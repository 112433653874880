import { View } from 'dripsy';
import React from 'react';

export class ErrorBoundary extends React.Component<any, any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    console.error('Error boundary caught an error', error);
    return { hasError: false };
  }

  componentDidCatch(error: any, info: { componentStack: any }) {
    console.error('Error boundary caught an error', error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || <View>Something went wrong.</View>;
    }

    return this.props.children;
  }
}
