import { Provider } from 'app/provider';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import 'raf/polyfill';
import '../global-polyfill';
import 'react-datepicker/dist/react-datepicker.css';
import * as WebBrowser from 'expo-web-browser';
import { Platform } from 'react-native';
import { useDim } from 'app/redux/hooks/useDim';
import axios from 'axios';
import { config } from 'app/redux/config';
import { ErrorBoundary } from 'app/provider/error-boundary';
import { P } from 'app/components/typography/p';
import { initTawkTo } from '../public/twakToService';
if (Platform.OS === 'web') {
  WebBrowser.maybeCompleteAuthSession();
}

// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
if (process.browser) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window._frameTimestamp = null;
}

function MyApp({ Component, pageProps, clientConfig }: any) {
  const [data, setData] = useState<any>({});
  const { scale } = useDim();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && typeof document != 'undefined') {
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    }
    // Initialize Tawk.to script when the app mounts (client-side only)
    if (typeof window !== 'undefined') {
      initTawkTo();
    }
  }, []);

  useEffect(() => {
    if (!clientConfig?.clientDetails?.useFirebase) return;
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) =>
          registration.pushManager.subscribe({
            userVisibleOnly: true
          })
        ).catch(e => {
          console.error(e);
        });
    }
  }, []);

  return (
    <ErrorBoundary fallback={<P>Something went wrong... Please inform the tech support.</P>}>
      <Head>
        <title>{data?.name}</title>
        <meta name="description" content={`${data?.name}`} />
        <link rel="icon" href={data?.logo} />
        <style>
          {`
          html { 
              zoom: ${1 / (Math.max(scale || 1, 1))};
              -moz-zoom: ${1 / (Math.max(scale || 1, 1))};
          }
          
            #cover-spin {
                position:fixed;
                width:100%;
                height: 100%;
                left:0;right:0;top:0;bottom:0;
                background-color: #FFFFFF;
                z-index:9999;
            }
            #cover-spin::after {
                content:'';
                display:block;
                position:absolute;
                left:48%;top:40%;
            }
            ::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }
            ::-webkit-scrollbar-track {
              border-radius: 10px;
              background: #D9D9D9;
            }
            ::-webkit-scrollbar-thumb{
              border-radius: 10px;
              background: #4C3E79;
            }
            ::-webkit-scrollbar-thumb:hover{
              background: #4C3E79;
            }
            ::-webkit-scrollbar-thumb:active{
              background: #4C3E79;
            }

            .accessibility-button {
              position: fixed;
              bottom: 20px;
              right: 20px;
              width: 50px;
              height: 50px;
              background-color: #007bff;
              color: white;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 24px;
              cursor: pointer;
              box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
              z-index: 1000;
            }

            .accessibility-popup {
              position: fixed;
              bottom: 80px;
              right: 20px;
              width: 300px;
              background-color: white;
              border-radius: 10px;
              padding: 20px;
              box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
              display: none;
              z-index: 1001;
            }

            .accessibility-popup h2 {
              margin-top: 0;
            }

            .option {
              margin-bottom: 10px;
            }

            .option label {
              display: block;
              margin-bottom: 5px;
            }

            .option input[type='range'] {
              width: 100%;
            }

            .button {
              margin-top: 10px;
              padding: 5px 10px;
              background-color: #007bff;
              color: white;
              border: none;
              border-radius: 5px;
              cursor: pointer;
            }

            .button:hover {
              background-color: #0056b3;
            }
        `}
        </style>
        {clientConfig?.clientDetails?.adsEnabled && !!clientConfig?.clientDetails?.adsText &&
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3654548293608117"
            crossOrigin="anonymous"
          ></script>
        }

      </Head>
      <Provider setData={setData}>
        <Component {...pageProps} />
      </Provider>
      <script async type='text/javascript' src='/accesibility.js'></script>
    </ErrorBoundary>
  );
}
MyApp.getInitialProps = async ({ ctx }: any) => {
  let domain = '';
  let protocol = 'https';
  if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    protocol = ctx?.req?.headers['x-forwarded-proto'] || 'https';
    domain = ctx?.req?.headers?.host;
  } else {
    domain = config?.domain;
  }
  if (domain) {
    const clientConfig = await axios
      .get(`${protocol}://${domain}/api/_accounts/api/v1/getClientDetails`, {
        params: {
          domain
        },
      })
      .then(({ data }) => (data && data.length ? data[0] : null))
      .catch(e => {
        console.error('protocol', ctx?.req?.headers['x-forwarded-proto'], protocol);
        console.error('domain', domain, `${protocol}://${domain}/api/_accounts/api/v1/getClientDetails`);
        console.error(e);
        throw e;
      });
    return {
      clientConfig: clientConfig,
    };
  } else {
    return {};
  }
};

export default MyApp;
