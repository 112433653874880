/* eslint-disable camelcase */
import { config } from '../../../packages/app/redux/config';
let isTawkScriptAdded = false;
import _ from 'lodash';

export const initTawkTo = () => {
  if (!isTawkScriptAdded) {
    isTawkScriptAdded = true;
    const tawkScript = document.createElement('script');
    tawkScript.async = true;
    tawkScript.src = `https://embed.tawk.to/${config.tawkID}`; // Ensure this ID is correct
    tawkScript.charset = 'UTF-8';

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tawkScript, firstScriptTag);

    let user = window.localStorage.getItem('user');
    try {
      user = JSON.parse(user);
    } catch (e) {
      console.error('Failed to parse user data:', e);
      user = null;
    }

    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    tawkScript.onload = () => {
      if (user?._id && window.Tawk_API) {
        window.Tawk_API.visitor = {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          phone: user.phone,
          hash: _.uniqueId(),
        };
      }
    };
  }
};