import { Action, Dispatch } from 'redux';
import {
  GET_USER_NOTIFICATIONS,
  GET_USER_NOTIFICATIONS_UPDATE,
} from '../constants';

export const getUserNotifications = (userID: string) => {}
    // (dispatch: Dispatch): Action =>
    //   dispatch({
    //     type: GET_USER_NOTIFICATIONS,
    //     payload: {
    //       client: 'notification',
    //       request: {
    //         url: `/user-notification-details?user=${userID}&type=pushNotification&$limit=50&$sort[createdAt]=-1`,
    //         method: 'GET',
    //       },
    //     },
    //   });

export const updateUserNotifications =
  (obj: any) => {}
    // (dispatch: Dispatch): Action =>
    //   dispatch({
    //     type: GET_USER_NOTIFICATIONS_UPDATE,
    //     payload: obj,
    //   });
