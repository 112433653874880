import { useEffect, useState } from 'react';

export const useCurrentRoute = (isQueryParams?: boolean) => {
  const _windowPath =
    typeof window !== 'undefined'
      ? !!isQueryParams
        ? `${window.location.pathname}${window.location.search}`
        : window.location.pathname
      : '';

  const [path, setPath] = useState('');

  useEffect(() => {
    setPath(_windowPath);
  }, [_windowPath]);
  return path;
};
