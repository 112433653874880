import {
  UPDATE_EDIT_CLASSROOM_DETAILS
  } from 'app/redux/constants';
  
  const initialState = {
    isAdmin:false
  };
  
  const classroom = (
    state = initialState,
    action: {
      type: any;
      payload: { data: { data: any } };
      error: { response: any; data: any };
    }
  ) => {
    switch (action.type) {
      case UPDATE_EDIT_CLASSROOM_DETAILS:{
        return { ...state,...action.payload };
      }
      default: {
        return state;
      }
    }
  };
  
  export default classroom;
  