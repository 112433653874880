import { makeUseAxiosHook, store } from 'app/redux/store';
import { Text } from 'dripsy';
import React, { memo, useEffect, useMemo } from 'react';
import { createContext } from 'react';
import { TextProps } from 'react-native';

export const I18Context = createContext({
  lang: 'en',
  setLang: (lang: string) => {
    // do nothing
    console.error('set lang', lang);
  },
  getLabel: (label?: string, data?: {[key:string]: any}) => {
    console.error('get label', label, data);
    return label || '';
  },
});

export const I18Provider = ({ children }: { children: React.ReactNode }) => {
  const globalStore = store.getState().globalStore;
  
  const [lang, setLang] = React.useState(globalStore?.locale ?? 'en');
  const [data, setData] = React.useState({});

  const [{}, fetchLang] = makeUseAxiosHook('sso', { manual: true })({
    url: `/api/lang/${lang}`,
    method: 'GET',
  });
  
  useEffect(()=>{
    if(globalStore?.locale){
      setLang(globalStore?.locale);
    }
  },[globalStore?.locale]);

  // const [unsetLabels, setUnsetLabels] = React.useState<string[]>([]);
  const unsetLabels = React.useRef<string[]>([]);
  useEffect(() => {
    // fetch JSON from server and set data for the language
    if(lang){
      fetchLang({
        url: `/api/lang/${lang}`,
        method: 'GET',
      })
        .then((res) => {
          if (res?.data) {
            setData(res.data);
          }
        })
        .catch((e) => {
          console.error('error', e);
        });
    }
  }, [lang]);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     if (unsetLabels.current?.filter(x => x).length) {
  //       fetchLang({
  //         url: '/api/lang',
  //         method: 'POST',
  //         data: unsetLabels.current?.map((label) => ({ text: label })),
  //       })
  //         .then(() => {
  //           // remove unset labels from unsetLabels
  //           // setUnsetLabels([]);
  //           unsetLabels.current = [];
  //           // refetch all labels
            
  //           fetchLang({
  //             url: `/api/lang/${lang}`,
  //             method: 'GET',
  //           })
  //             .then((res) => {
  //               if (res?.data) {
  //                 setData(res.data);
  //               }
  //             })
  //             .catch((e) => {
  //               console.error('error', e);
  //             });
  //         })
  //         .catch((e) => {
  //           console.error('error', e);
  //         });
  //     }
  //   }, 10000);
  //   return () => clearInterval(timer);
  // }, [lang]);

  const getLabel = (label?: string, inputData: {[key:string]: any} = {}) => {
    if (!label) return '';
    if (!data[label]) {
      if (!unsetLabels.current.includes(label)) {
        // setUnsetLabels((l) => [...l, label]);
        unsetLabels.current = [...unsetLabels.current, label];
      }
    }
    // curly baseses based string template
    // let label = 'Hello {name}';
    // let data = {'Hello {name}': 'नमस्ते {name}'};
    // let inputData = {name: 'Vishal'};
    // console.log(getLabel(label, data, inputData)); // output: नमस्ते Vishal
    return (data[label] || label).replace(
      /{([^}]+)}/g,
      (_: any, key: string | number) => `${inputData[key] || ''}`
    );
  };
  const obj = useMemo(() => ({ lang, setLang, getLabel }), [data]); // value is cached by useMemo
  return (
    <I18Context.Provider value={obj}>
      {children}
    </I18Context.Provider>
  );
};

export const useI18 = () => React.useContext(I18Context);

/**
 * E.g. 
 * <I18Text label="Hello {name}" data={{name: 'Vishal'}} />
 * <I18Text data={{name: 'Vishal'}}>{"Hello {name}"}</I18Text>
 * <I18Text>Menu 1</I18Text>
 * <I18Text label="Menu 1" />
 * @param props 
 * @returns 
 */

export const I18Text = memo((props: TextProps & { label?: string; data?: {[key:string]: any} }) => {
  const { label, data, children, ...rest } = props;
  const { getLabel } = useI18();
  return (
    <Text {...rest}>
      {label || (typeof children === 'string' ? children : undefined)
        ? getLabel(
            label || (typeof children === 'string' ? children : undefined),
            data
          )
        : children}
    </Text>
  );
});
