// PortalHost.js
import React, { useState, useContext, createContext, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';

const PortalContext = createContext<any>(null);

export const PortalHost = ({ children }) => {
  const [portals, setPortals] = useState<any[]>([]);

  const registerPortal = (key, portal) => {
    setPortals((prev) => [...prev, { key, portal }]);
  };

  const unregisterPortal = (key) => {
    setPortals((prev) => prev.filter((p) => p.key !== key));
  };
  const obj = useMemo(() => ({ registerPortal, unregisterPortal }), []); // value is cached by useMemo

  return (
    <PortalContext.Provider value={obj}>
      <View style={styles.container}>
        {children}
        {portals.map(({ key, portal }) => (
          <React.Fragment key={key}>{portal}</React.Fragment>
        ))}
      </View>
    </PortalContext.Provider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export const usePortal = () => {
  const context = useContext(PortalContext);

  if (!context) {
    throw new Error('usePortal must be used within a PortalHost');
  }

  return context;
};
