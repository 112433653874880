import { ToastMessage } from 'app/redux/reducers/toasts';
import { uuidv4 } from 'app/util/helper';
import { useDispatch } from 'react-redux';

export const useToast = () => {
    const dispatch = useDispatch();
  
    return function sendMessage(message: ToastMessage) {
      dispatch({
        type: 'ADD_MESSAGE',
        payload: {
          id: uuidv4(),
          message,
        },
      });
    };
  };