// import { combineReducers } from 'redux';
import user from './user.reducers';
import token from './token.reducer';
import toasts from './toasts';
import storeConfig from './store-config.reducer';
import loaded from './loaded.reducer';
import permission from './permission.reducer';
import preference from './preference.reducer';
import client from './client.reducer';
import wallet from './wallet.reducer';
import welcomePopup from './welcomePopup.reducer';
import notification from './notification.reducer';
import globalStore from './global.reducer';
import classroom from './classroom.reducer';
import clientConfig from './client-confg.reducer';
import { LOGOUT_SUCCESS } from '../constants';
import userLogout from '../hooks/user-logout';
// import { BackHandler, Platform } from 'react-native';
// if(!combineReducers) {
//   console.error('Please upgrade redux to v4.x.x or higher', combineReducers);
//   // combineReducers = (redux as any).default.combineReducers;
// }
import { combineReducers } from '@reduxjs/toolkit';
import { structureModalReducer } from './structure-modal.reducer';

const appReducer = combineReducers({
  user,
  token,
  storeConfig,
  toasts,
  loaded,
  permission,
  preference,
  client,
  wallet,
  welcomePopup,
  notification,
  globalStore,
  classroom,
  clientConfig,
  structureModalReducer,
});

const rootReducer = (state: any, action: any) => {   
  // Clear all data in redux store to initial.
  if(action.type === LOGOUT_SUCCESS || action.type === 'LOGOUT_FAIL'){
     userLogout()
     .then(() => {
        // if(Platform.OS != 'web')
        //   BackHandler.exitApp();
     });
     state = {
      ...state, 
      user: null,
      token: '',
      preference: null, permission: [],
      wallet: { cartItems: [] },
      notification: {},
      classroom: {
        isAdmin:false
      }
     };
    }
  
  
  return appReducer(state, action);
};

export default rootReducer;