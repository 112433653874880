import { setAsyncStorageData } from 'app/util/helper';
import AsyncStorage from '@react-native-async-storage/async-storage';

const setUserRegistered = async (state: boolean) => {
  try {
    if(state) {
      await setAsyncStorageData('userRegistered', state.toString());
    } else {
      AsyncStorage.removeItem('userRegistered');
    }
  } catch (e) {
    console.error(e);
  }
};

const welcomePopup = (state = false, action: { type: any, payload: boolean }) => {
  switch (action.type) {
    case 'SET_WELCOME_POPUP':
      setUserRegistered(action.payload);
      return action.payload;
    default:
      return state;
  }
};
export default welcomePopup;
