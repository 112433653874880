import {
    GET_USER_CLIENT_SUCCESS,
  } from 'app/redux/constants';
  
  const initialState = {};
  
  const userClient = (
    state = initialState,
    action: {
      type: any;
      payload: { data: { data: any } };
      error: { response: any; data: any };
    }
  ) => {
    switch (action.type) {
      case GET_USER_CLIENT_SUCCESS: {
        return action.payload.data?.data?.length ?  action.payload.data?.data[0]: {};
      }
      // case LOGOUT_SUCCESS: {
      //   // setTimeout(userLogout, 5);
      //   return {};
      // }
      // case 'LOGOUT_FAIL': {
      //   // setTimeout(userLogout, 5);
      //   return {};
      // }
      default: {
        return state;
      }
    }
  };
  
  export default userClient;
  