import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
// import performance, { PerformanceObserver } from 'react-native-performance';

const PerformanceContext = createContext<any>(null);

export const PerformanceProvider = ({ children }) => {
  const measurePerformance = (componentName: string) => {
    // performance.measure(`${componentName} render`);
    // const observer = new PerformanceObserver(() => {
    //   console.log(`${componentName} render time:`,performance.getEntriesByName(`${componentName} render`));
    // });
    // observer.observe({ type: 'measure', buffered: true });
    // return () => {
    //   observer.disconnect();
    //   performance.clearMeasures(`${componentName} render`);
    // };
  };

  return (
    <>
      {children}
    </>
  );
};

export const usePerformance = (componentName) => {
  const measurePerformance = useContext(PerformanceContext);

  useEffect(() => {
    if (measurePerformance) {
      return measurePerformance(componentName);
    }
  }, [componentName, measurePerformance]);
};
