import React from 'react';
import { Image, Text,  View } from 'react-native';
import { useDim } from 'app/redux/hooks/useDim';
import { useSelector } from 'react-redux';
import { getClientConfig } from '../../redux/memoizedSelectors';

interface LoaderProps {
  title?: string;
  children?: any;
}

export const Loader = ({ children, title = 'Loading...' }: LoaderProps) => {
  const { width } = useDim();
  const { clientConfig: clientConfigData } = useSelector(getClientConfig);
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFF',
        width: 'auto',
        height: '100%',
      }}
    >
      <Image
        source={clientConfigData.loader}
        defaultSource={require('../../assets/logo-book.gif')}
        resizeMode='contain'
        style={{
          width: width > 700 ? 500 : '100%',
          height: width > 500 ? 500 : 300,
          alignSelf: 'center',
          justifyContent: 'center',
        }}
      />
      <Text>{title}</Text>
      {children}
    </View>
  );
};
