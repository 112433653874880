import { UPDATE_CLIENT_CONFIG } from '../constants';

const initialState = {
  onlyClassRoom: false,
  onlyNotes: false,
  clientId: '1',
  // logo: require('../../assets/logo.webp'),
  // loader: require('../../assets/logo-ani.gif'),
  menu: [
    'Dashboard',
    'ExamAdda',
    'Syllabus',
    'Saved Material',
    'Course Marketplace',
    'Subscription',
    'Upload',
    'Wallet',
    'Smart Classroom',
    'Campus Ambassador',
    'Refer and Earn',
    'Settings',
    'Help & Support',
    'About Us',
    'Blogs',
    'MentorShip Program'

  ],
  classroomTabs: {
    Quiz: { displayName: 'Quiz', visible: true, key: 'quiz' },
    About: { displayName: 'About', visible: true, key: 'about' },
    Post: { displayName: 'Post', visible: true, key: 'post' },
    LiveEvent: { displayName: 'Live Events', visible: true, key: 'liveEvents' },
    Assignment: { displayName: 'Assignments', visible: true, key: 'assignment' },
  }
};
const clientConfig = (
  state =initialState,
  action: {
    payload: any; type: any 
}
) => {
  switch (action.type) {
    case UPDATE_CLIENT_CONFIG:
      return { ...state,...action.payload };
    default:
      return state;
  }
};

export default clientConfig;
