import reducer from './reducers';
import { thunk } from 'redux-thunk';
import axios, { AxiosRequestConfig } from 'axios';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { config } from './config';
import { makeUseAxios, Options, UseAxiosResult } from 'axios-hooks';
import { useSelector } from 'react-redux';
import asyncDispatchMiddleware from './middleware/async-dispatch';
import { Platform } from 'react-native';
import { getAsyncStorageData, getCookie } from 'app/util/helper';
import { SET_TOKEN } from './constants';
import { configureStore } from '@reduxjs/toolkit';
import Mustache from 'mustache';
import navigateOnSuccessMiddleware from './middleware/navigate-on-success';
import { persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import AsyncStorage from '@react-native-community/async-storage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getStoreConfig } from './memoizedSelectors';
import { getToken as getTokenMem } from './memoizedSelectors';
import userLogout from './hooks/user-logout';


export const client: any = {
  default: {
    client: axios.create({
      baseURL: config.baseUrl,
    }),
  },
  search: {
    client: axios.create({
      baseURL: config.searchBaseUrl,
    }),
  },
  content: {
    client: axios.create({
      baseURL: config.contentBaseUrl,
    }),
  },
  academic: {
    client: axios.create({
      baseURL: config.academicsBaseUrl,
    }),
  },
  sso: {
    client: axios.create({
      baseURL: config.ssoBaseUrl,
    }),
  },
  faq: {
    client: axios.create({
      baseURL: config.faqBaseUrl,
    }),
  },
  billing: {
    client: axios.create({
      baseURL: config.billingBaseUrl,
    }),
  },
  notification: {
    client: axios.create({
      baseURL: config.notificationBaseUrl,
    }),
  },
  marketplace: {
    client: axios.create({
      baseURL: config.lnMarketplace,
    }),
  },
  classroom:{
    client: axios.create({
      baseURL: config.classroomBaseUrl,
    }),
  },
  testplateform:{
    client: axios.create({
      baseURL: config.testPlatformBaseUrl,
    }),
  },
  clientbilling:{
    client: axios.create({
      baseURL: config.clientBillingBaseUrl,
    }),
  },
  ttss:{
    client: axios.create({
      baseURL: config.ttssBaseUrl,
    }),
  },
  chats: {
    client: axios.create({
      baseURL: config.chatBaseUrl
    })
  }
};

axios.interceptors.request.use((req) => {
  req.baseURL = config.baseUrl;
  req.timeout = 60000;
  return req;
});

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
export const Base64 = {
  btoa: (input:string = '')  => {
    let str = input;
    let output = '';

    for (let block = 0, charCode, i = 0, map = chars;
    str.charAt(i | 0) || (map = '=', i % 1);
    output += map.charAt(63 & block >> 8 - i % 1 * 8)) {

      charCode = str.charCodeAt(i += 3/4);

      if (charCode > 0xFF) {
        throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
      }

      block = block << 8 | charCode;
    }

    return output;
  },

  atob: (input:string = '') => {
    let str = input.replace(/=+$/, '');
    let output = '';

    if (str.length % 4 == 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (let bc = 0, bs = 0, buffer, i = 0;
      buffer = str.charAt(i++);

      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      buffer = chars.indexOf(buffer);
    }

    return output;
  }
};

function isJwtTokenExpired(token) {
  if (!token) {
    return true; // If no token is provided, consider it expired
  }

  try {
    // Split the token into its parts
    const payloadBase64 = token.split('.')[1];
    if (!payloadBase64) {
      return true; // Invalid token format
    }

    // Decode the base64-encoded payload
    const payload = JSON.parse(Base64.atob(payloadBase64));

    // Check if the token has an expiration time (`exp` claim)
    if (!payload.exp) {
      return false; // No expiration claim, consider it expired
    }

    // Convert the expiration time to milliseconds
    const exp = payload.exp * 1000;
    // Check if the current time is past the expiration time
    if (Date.now() >= exp) {
      return true; // Token is expired
    }

    return false; // Token is valid
  } catch (error) {
    // If any error occurs (e.g., malformed token), consider it expired
    return true;
  }
}

export const getToken = async () => {
  if (!store) {
    return;
  }
  if (store.getState().token) {
    store.dispatch({
      type: 'GLOBAL_LOADED',
    });
    if(isJwtTokenExpired(store.getState().token)){
      store.dispatch({
        type: 'LOGOUT_SUCCESS',
      });
      userLogout();
      return ;
    }else{
      return store.getState().token;
    }
  } else if (Platform.OS === 'web') {
      const token = getCookie(config.authTokenName);
      if (token) {
        if(isJwtTokenExpired(token)){
          store.dispatch({
            type: 'LOGOUT_SUCCESS',
          });
          userLogout();
          return;
        } else 
          store.dispatch({
            type: SET_TOKEN,
            payload: token,
          });
      } else {
        store.dispatch({
          type: 'GLOBAL_LOADED',
        });
      }
      return token;
    } else {
      const token = await getAsyncStorageData(config.authTokenName);
      if (token) {
        if(isJwtTokenExpired(token)){
          store.dispatch({
            type: 'LOGOUT_SUCCESS',
          });
          userLogout();
          return;
        } else 
          store.dispatch({
            type: SET_TOKEN,
            payload: token || '',
          });
      } else {
        store.dispatch({
          type: 'GLOBAL_LOADED',
        });
      }
      return token;
    }
};

// Get Token from cookie or async storage
// if found,
// 1. set token in state - by calling setToken action
// 2. call me api by using redux action called USER_REFRESH -

const axiosMiddlewareConfig = {
  interceptors: {
    request: [
      function (
        {}: any,
        req: {
          baseURL: any;
          timeout: number;
          headers: {  Authorization?: string; credentials?: string  };
        }
      ) {
        req.timeout = 60000;
        const token = store.getState().token;

        if (token) {
          req.headers.Authorization = `Bearer ${token}`;
        } else {
          delete req.headers['Authorization'];
        }
        req.headers.credentials = 'include';
        // req.headers.Authorization = `Bearer ${'eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyJ9.eyJzdWIiOiI1ZmU0NzBhNjcyYzczNTAwMmQ5YTkyYjgiLCJmaXJzdE5hbWUiOiJMZW5pbiIsImxhc3ROYW1lIjoiTW9oYXBhdHJhIiwicm9sZSI6NywidXNlcm5hbWUiOiJsZW5pbl9tb2hhIiwiaWF0IjoxNjU5NjMwNTI0LCJhdWQiOiJodHRwczovL2FjY291bnRzLmxlY3R1cmVzbm90ZXMuY29tIiwiaXNzIjoiZmVhdGhlcnMiLCJqdGkiOiJkM2RjZjZmMS04NjE2LTQ3NzYtODU3Yi04NzI4Y2IxMGM0ZWYifQ.6I37PfZhtefBiptNJKMLpxRvGeg2bd4-1eOeRupceHk'}`
        return req;
      },
    ],
    response: [
      {
        success: function ({}, res: any) {
          if (res.config && res.config.method === 'get') {
            if (res.data.data && res.data.total && res.data.limit) {
              const { total, limit, skip = 0, ...othersData } = res.data;
              res.data = {
                ...othersData,
                hasMore: total > limit + skip,
                total,
                limit,
                skip,
              };
            }
          }
          store;
          return Promise.resolve(res);
        },
        error: function ({}, error: { response: { status: number } }) {
          if (error && error.response && error.response.status === 401) {
            // unauthorized - redirect to login
          }
          return Promise.reject(error);
        },
      },
    ],
  },
};

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: ['storeConfig', 'toasts','token','classroom']
};

const persistentReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: ((typeof window === 'undefined' && Platform.OS === 'web') ? reducer : persistentReducer) as any,
  devTools: true,// process.env.NODE_ENV !== 'production',
  preloadedState: {},
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  middleware: () => [
    thunk,
    multiClientMiddleware(client, axiosMiddlewareConfig),
    asyncDispatchMiddleware,
    navigateOnSuccessMiddleware,
  ],
});

export const persistor = persistStore(store);


getToken();

export function makeUseAxiosHook(clientName: string, options?: Options) {
  const axiosClient = (client[clientName] || client['default']).client;
  return function useAxiosHook(
    axiosConfig: string | AxiosRequestConfig<any>,
    data?: any
  ): UseAxiosResult<any, any, any> {
    const { storeConfig } = useSelector(
      getStoreConfig
    );

    if (typeof axiosConfig === 'string' && storeConfig.urls[axiosConfig]) {
      axiosConfig = Mustache.render(storeConfig.urls[axiosConfig], data || {});
    } else if (
      typeof axiosConfig === 'object' &&
      axiosConfig.url &&
      storeConfig.urls[axiosConfig.url]
    ) {
      axiosConfig.url = Mustache.render(
        storeConfig.urls[axiosConfig.url],
        data || {}
      );
    }

    const axiosInstance = axiosClient.create(); // Create a new axios instance
    const { token } = useSelector(getTokenMem);
    // Add request and response interceptors to the new instance
    axiosInstance.interceptors.request.use((req: any) => {
      if (
        typeof axiosConfig === 'object' &&
        axiosConfig.params &&
        Object.entries(axiosConfig.params).length
      ) {
        const q = req.url.split('?');
        const existingQ = q.length === 2 ? q[1].split('&') : [];
        const queryString = Object.entries(axiosConfig.params)
          .map((e) => (e[1] ? `${e[0]}=${e[1]}` : ''))
          .filter((e) => e != '' && !existingQ.includes(e))
          .join('&');

        if (queryString)
          req.url = req.url + (req.url.includes('?') ? '&' : '?') + queryString;

        delete axiosConfig.params;
      }
      if (token) {
        req.headers.Authorization = `Bearer ${token}`;
      } else if(req?.headers) delete req?.headers?.Authorization;
      

      const query = req.url.split('?');
      const str: string[] = [];
      if (query.length === 2) {
        const queryList = query[1].split('&');
        for (const index in queryList) {
          const [key, value] = queryList[index].split('=');
          if (typeof value === 'boolean' || value === 0 || value) {
            str.push(`${key}=${encodeURI(value)}`);
          }
        }
      }
      req.url = `${query[0]}${str.length ? '?' + str.join('&') : ''}`;

      return req;
    });

    axiosInstance.interceptors.response.use((res: any) => {
      if (res.config && res.config.method === 'get') {
        if (res.data.data && res.data.total && res.data.limit) {
          const { total, limit, skip = 0, ...othersData } = res.data;
          res.data = {
            ...othersData,
            hasMore: total > limit + skip,
            total,
            limit,
            skip,
          };
        }
      }
      return res;
      });

    const result = makeUseAxios({
      axios: axiosInstance, // Use the new axios instance
    })(axiosConfig, options);

    return result;
  };
}
