import { P as DP, styled, useSx } from 'dripsy';
import React from 'react';
import { LNTextProps } from './h3';

export const P = styled(({ style: styles, ...props }: LNTextProps) => {
  const sx = useSx();

  return <DP accessibilityRole='text' style={[sx({
    marginY: 0,
  }), styles]} {...props} />;
})({
  margin: 0,
  color: '$textColor',
  fontSize: 12
});
