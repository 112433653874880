export const SEARCH = 'SEARCH';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';

export const USER_REFRESH = 'USER_REFRESH';
export const USER_REFRESH_SUCCESS = 'USER_REFRESH_SUCCESS';
export const USER_REFRESH_FAIL = 'USER_REFRESH_FAIL';

export const GET_USER_PERMISSION = 'GET_USER_PERMISSION';
export const GET_USER_PERMISSION_SUCCESS = 'GET_USER_PERMISSION_SUCCESS';

export const GET_USER_PREFERENCE = 'GET_USER_PREFERENCE';
export const GET_USER_PREFERENCE_SUCCESS = 'GET_USER_PREFERENCE_SUCCESS';
export const UPDATE_USER_PREFERENCE='UPDATE_USER_PREFERENCE';
export const UPDATE_USER_DATA='UPDATE_USER_DATA';

export const GET_USER_CLIENT = 'GET_USER_CLIENT';
export const GET_USER_CLIENT_SUCCESS = 'GET_USER_CLIENT_SUCCESS';

export const SET_TOKEN = 'SET_TOKEN';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAIL';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const FETCH_USER = 'FETCH_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const CHECK_USER = 'CHECK_USER';
export const CHECK_USER_SUCCESS = 'CHECK_USER_SUCCESS';
export const CHECK_USER_FAILURE = 'CHECK_USER_FAIL';
export const SEND_OTP = 'SEND_OTP';
export const OTP_LOGIN = 'OTP_LOGIN';
export const VERIFY_OTP = 'VERIFY_OTP';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const GET_USER_CARTS_DETAILS = 'GET_USER_CARTS_DETAILS';
export const GET_USER_CARTS_DETAILS_SUCCESS = 'GET_USER_CARTS_DETAILS_SUCCESS';
export const GET_USER_CARTS_DETAILS_FAIL = 'GET_USER_CARTS_DETAILS_FAIL';
export const GET_USER_CARTS_DETAILS_UPDATE = 'GET_USER_CARTS_DETAILS_UPDATE';

export const SET_WELCOME_POPUP = 'SET_WELCOME_POPUP';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';
export const GET_USER_NOTIFICATIONS_UPDATE = 'GET_USER_NOTIFICATIONS_UPDATE';

export const UPDATE_GLOBAL_STORE_DATA='UPDATE_GLOBAL_STORE_DATA';

export const UPDATE_EDIT_CLASSROOM_DETAILS = 'UPDATE_EDIT_CLASSROOM_DETAILS';

export const UPDATE_CLIENT_CONFIG='UPDATE_CLIENT_CONFIG';

export const UPDATE_EDIT_TEMPLATE_DETAILS='UPDATE_EDIT_TEMPLATE_DETAILS';