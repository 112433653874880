import React, {
  createContext,
  use,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { getToken, getUser } from '../redux/memoizedSelectors';
import { config } from '../redux/config';

const WebSocketContext = createContext<{
  subscribe: (chatGroup: string | number, callback: any) => void;
  unsubscribe: (chatGroup: string | number, callback: any) => void;
  ready: boolean;
}>({
  subscribe: () => {},
  unsubscribe: () => {},
  ready: false,
});

export const useChat = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

export const ChatProvider = ({ children }) => {
  const [socket, setSocket] = useState<WebSocket>();
  // const [subscribers, setSubscribers] = useState<any>({});
  const subscribers = useRef<any>({}); // to avoid re-render
  const [ready, setReady] = useState(false);
  const { token } = useSelector(getToken);

  useEffect(() => {
    if (token) {
      const ws = new WebSocket(config.chatWsUrl);
      setSocket(ws);
    }
  }, [token]);

  useEffect(() => {
    // Create WebSocket connection
    if (!socket) return;
    socket.onopen = () => {
      console.log('WebSocket connected');
    };

    socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        if(message.msg === 'Logged in') {
          setReady(true);
        }
        const { chatGroup } = message;
        console.log('WebSocket message:', subscribers.current);
        if (subscribers.current[chatGroup]) {
          subscribers.current[chatGroup].forEach(
            (callback: (arg0: any) => any) => callback(message)
          );
        }

      } catch (_ignore) {}
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onopen = () => {
      // send auth token
      socket.send(JSON.stringify({ type: 'authenticate', token: token }));
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
      // reconnect
      setTimeout(()=>{
        setSocket(new WebSocket(config.chatWsUrl));
      },30000);
    };

    const pinpPongTimer = setInterval(() => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.send('{"type": "ping"}');
      }
    }, 30000);

    // Clean up WebSocket connection on unmount
    return () => {
      if (socket) {
        socket.close();
      }
      clearInterval(pinpPongTimer);
    };
  }, [socket]);

  const subscribe = (chatGroup: string | number, callback: any) => {
    subscribers.current = {
      ...subscribers.current,
      [chatGroup]: [...(subscribers.current[chatGroup] || []), callback],
    };
    socket?.send(JSON.stringify({ type: 'subscribe', payload: { chatGroup } }));
  };

  const unsubscribe = (chatGroup: string | number, callback: any) => {
    subscribers.current = {
      ...subscribers.current,
      [chatGroup]: subscribers.current[chatGroup].filter(
        (cb: any) => cb !== callback
      ),
    };
  };

  return (
    <WebSocketContext.Provider value={{ subscribe, unsubscribe, ready }}>
      {children}
    </WebSocketContext.Provider>
  );
};
