import useOnlineStatus from 'app/redux/hooks/useOnlineStatus';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'solito/router';
import Dialog from '../dialog';
import { View } from 'dripsy';
import { Button } from '../button/common-button';
import DialogAction from '../dialog/dialog-action';
import { useSelector } from 'react-redux';
import { H2 } from '../typography/h2';
import { Icon } from '../icon/icon';
import { getUser } from '../../redux/memoizedSelectors';

export const Offline = () => {
    const { user } = useSelector(getUser);
    const { push } = useRouter();
    const isOnline = useOnlineStatus();
    const [isOffline, setOffline] = useState(false);

    useEffect(() => {
        setOffline(!isOnline);
    }, [isOnline]);

    return (
        <Dialog
            modalVisible={isOffline}
            // onCloseModal={() => setOpen(false)}
            styles={{
                borderRadius: 5,
                minWidth: 300,
                maxWidth: 300,
                padding: 15

            }}
        >
            <View
                style={{
                    minHeight: 120,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Icon name='noInternet' />
                <H2>You Are Offline</H2>
            </View>

            <DialogAction
            style={{
                justifyContent:user?'space-between':'flex-end',
            }}
            >
                <Button
                    style={{
                        flex:1,
                      minWidth:100
                    }}
                    textColor={'grey'}
                    variantColor="primary"
                    variantType="outlined"
                    onPress={() => {
                        
                    }}
                >
                    Exit
                </Button>

                {user ? <Button
                    style={{
                      flex:1,
                    minWidth:150
                    }}
                    variantColor="primary"
                    variantType="contained"
                    loadingIndicatorSize={18}
                    onPress={() => {
                        push('/saved-material');
                        setOffline(false);
                    }}
                >
                 Offline Downloads
                </Button> : <></>}
            </DialogAction>
        </Dialog>
    );
};
