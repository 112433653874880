const navigateOnSuccessMiddleware =
  () =>
  (next:any) =>
  (action: {
    type: string
    meta: any
  }) => {
    
    if (
      action.type &&
      action.type.endsWith('SUCCESS') &&
      action.meta?.previousAction?.navigate &&
      isFunction(action.meta?.previousAction?.navigate)
    ) {
      action.meta?.previousAction?.navigate();
    }
    const res = next(action);
    return res;
  };

function isFunction(functionToCheck: any) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  );
}

export default navigateOnSuccessMiddleware;
